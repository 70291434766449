<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Terrassenüberdachung / Lewens / Murano Puro <br />
    </h2>
    <div class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Murano Puro - Glasdach
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full md:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full md:col-span-1">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Wetterschutz für kleine und mittlere Terrassen
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Outdoor-Living pur: Das Glasdach Murano Puro erweitert den
            häuslichen Wohnraum und ist dabei solide, vielseitig und attraktiv
            im Preis. Da Rinne und Pfosten bündig in einer Linie stehen, bietet
            Murano Puro maximalen Raum und macht auch aus kleinen Terrassen
            wahre Wohlfühlplätze. Praktisches Detail ist die unsichtbar
            integrierte Wasserableitung durch die Pfosten.<br /><br />
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Allgemein">
            <p class="text-lg">
              <span class="font-bold">Details </span> <br />
              Outdoor-Living pur: Das Glasdach Murano Puro erweitert den
              häuslichen Wohnraum und ist dabei solide, vielseitig und attraktiv
              im Preis. Da Rinne und Pfosten bündig in einer Linie stehen,
              bietet Murano Puro maximalen Raum und macht auch aus kleinen
              Terrassen wahre Wohlfühlplätze. Praktisches Detail ist die
              unsichtbar integrierte Wasserableitung durch die Pfosten.
            </p>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 700 cm - 1-teilig (Einzelfeld)<br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 350 cm <br />
              <span class="font-bold">Verglasung:</span> <br />
              für VSG 8 mm <br />
              für VSG 10 mm <br />
              für VSG 12 mm <br />
              für Doppelstegplatten 16 mm <br />
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div>
                    <img
                      src="../assets/madeingermany.webp"
                      alt="made in germany siegel"
                    />
                  </div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div><img src="../assets/ce-norm.webp" alt="ce-norm" /></div>
                  <div class="w-full pl-1">
                    Qualität und Technik nach CE-Norm
                  </div>
                </div>
                <div class="flex flex-row">
                  <div>
                    <img src="../assets/tuev.webp" alt="tuev geprüft" />
                  </div>
                  <div class="w-full pl-1">TÜV-geprüfte Sicherheit</div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </div>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/puro/lew-murano-puro-b-ganz-haus-uli.webp"),
          alt: "murano puro glasdach",
        },
        {
          image: require("@/assets/img/lewens/puro/modelle-web-rd-225.webp"),
          alt: "glasdach puro detail ansicht",
        },
        {
          image: require("@/assets/img/lewens/puro/lew-murano-puro-b-detail-uli.webp"),
          alt: "glasdach puro detail ansicht schraeg",
        },
        {
          image: require("@/assets/img/lewens/puro/lew-murano-puro-b-detail-rinne-uli.webp"),
          alt: "glasdach puro detail ansicht",
        },
      ],
    };
  },
};
</script>
